.purchases {
	height: calc(
		100vh - var(--bottom-bar-height) - var(--navbar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
	padding-top: var(--navbar-height);
}

.card-list-container {
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
}

.card-item {
	cursor: pointer;
	display: flex;
	min-height: 50px;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 15px 20px;
	margin: 10px 0px;
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 5px 5px 10px #f0f0f0, -5px -5px 10px #ffffff;
	border: 1px solid #e0e4ea;
	margin: 10px;
}

.card-item:hover {
	box-shadow: 5px 5px 10px #cfcfcf, -5px -5px 10px #ffffff;
}

.card-item > div {
	width: 100%;
	min-width: 0;
	overflow: hidden;
	text-overflow: clip;
	white-space: wrap;
}

.card-item > .title {
	font-size: 14px;
}

.card-item > .description {
	margin-top: auto;
	font-size: 11px;
}

@media screen and (min-width: 769px) {
	.purchases {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: none;
	}

	.purchases > div {
		width: 100%;
	}

	.purchases > footer {
		display: none !important;
	}
}

@media screen and (max-width: 767px) {
	.purchases {
		height: calc(
			100vh - var(--bottom-bar-height) -
				env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: auto;
	}

	.home > footer {
		display: flex !important;
	}
}
