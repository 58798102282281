.installation-dialog {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.installation-dialog button {
	cursor: pointer;
	border: none;
	background: none;
}

.installation-dialog button div {
	width: 24px;
	height: 24px;
	/*-webkit-mask: url(/assets/icon/x.svg) no-repeat center;
	mask: url(/assets/icon/x.svg) no-repeat center;*/
}

.installation-dialog .container {
	display: flex;
	flex-direction: column;
	width: clamp(300px, 50vw, 800px);
	height: clamp(300px, 40vh, 800px);
}

.installation-dialog .container .close-container {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
}

.installation-dialog .container .close-container button svg {
	stroke: #fff;
}

.installation-dialog .container .content {
	width: 100%;
	height: 100%;
	background: #fff;
	color: #000;
	padding-top: 20px;
	border-radius: 4px;
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 50px;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.installation-dialog .container .content h3 {
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 10px;
}

.installation-dialog .container .content h3 i {
	font-style: italic;
}

.installation-dialog .container .content p {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0px 40px;
	margin-bottom: 20px;
}

.installation-dialog .container .content > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.installation-dialog .container .content > :first-child img {
	width: 80px;
	margin-bottom: 20px;
}

.installation-dialog .container .content > :last-child {
	background: #f9f9f9;
	font-size: 0.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.installation-dialog .container .content > :last-child svg {
	width: 20px;
	margin: 0px 5px;
	stroke: #4e97fd;
}

.install-btn {
	cursor: pointer;
	background: #fafafa;
	color: rgba(0, 122, 255, 1);
	width: 60px;
	height: 30px;
	font-weight: bold;
	border-radius: 8px;
	border: 1px solid rgba(0, 122, 255, 1);
	transition: border ease-out 220ms, background ease-out 220ms,
		color ease-out 220ms;
}

.install-btn:hover {
	background: rgba(0, 122, 255, 1);
	color: #fafafa;
}
