.installation-option {
	height: 100vh;
	width: 100vw;
	background: rgba(46, 46, 46, 0.75);
	opacity: 1;
	position: fixed;
	z-index: 300;
}

.installation-option-ios {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: center;
}

.installation-option-ios > :first-child {
	height: 250px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 110px;
}

.installation-option-ios > :first-child > .caret {
	width: 90%;
	height: 20px;
	display: flex;
	justify-content: center;
}

.installation-option-ios > :first-child > .caret > div {
	width: 20px;
	height: 20px;
	position: absolute;

	border-left: 20px solid transparent;
	border-right: 20px solid transparent;

	border-top: 20px solid #fff;
}

.ios-content {
	background: #fff;
	display: flex;
	position: relative;
	flex-direction: column;
	width: 90%;
	height: 90%;
	border-radius: 8px;
	-webkit-box-shadow: 1px 3px 21px -4px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 1px 3px 21px -4px rgba(0, 0, 0, 0.75);
	box-shadow: 1px 3px 21px -4px rgba(0, 0, 0, 0.75);
}

.ios-content > div {
	width: 100%;

	margin-bottom: 5px;
}

.ios-content > .title {
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.ios-content > div > .container {
	width: 90%;
	display: flex;

	justify-content: flex-start;
}

.container > .icon {
	margin-left: 15px;
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
		0 1px 2px 0 rgba(0, 0, 0, 0.06);
	-moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
		0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.container > h1 {
	font-size: 14pt;
	color: #3d3d3d;
	margin-left: 20px;
}

.ios-content > .message {
	width: 100%;
	height: 40px;
	font-size: 12pt;
	display: flex;
	justify-content: center;
}

.message > .container > p {
	padding-left: 20px;
	padding-right: 20px;
}

.ios-content > .close {
	width: 20px;
	height: 20px;
	top: 0;
	right: 0;
	cursor: pointer;
	position: absolute;
	margin-top: 10px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 767px) {
	.installation-option-ios {
		margin-top: 25px;
		justify-content: flex-start;
	}

	.installation-option-ios > :first-child {
		align-items: flex-end;
		margin-right: 50px;
	}

	.ios-content {
		width: 400px;
		height: 200px;
	}

	.installation-option-ios > :first-child > .caret {
		opacity: 0;
	}
}
