.lightbox {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	background: rgba(0, 0, 0, 0.9);
	z-index: 500;
	width: 100%;
	max-width: 100%;
	height: 100%;
	opacity: 1;
	padding: 0;
	transition: opacity ease-out 220ms, z-index ease-out 250ms;
}

.lightbox.hide {
	z-index: -100;
	opacity: 0;
}

.lightbox .container {
	width: 90%;
	height: 90%;
	max-height: 90%;
	min-height: 90%;
	max-width: 90%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 30px calc(100% - 30px);
	gap: 0px 0px;
	overflow: hidden;
	grid-template-areas:
		'.'
		'.';
}

.lightbox .container > div {
	padding: 0px 20px;
}

.lightbox .container > :first-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.lightbox .container > :first-child button {
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: none;
}

.lightbox .container .component {
	position: relative;
	max-height: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.arrow {
	position: absolute;
	top: 50%;
	margin: 0 auto;
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: none;
}

.arrow.left {
	left: 0;
	margin-left: 10px;
}

.arrow.right {
	right: 0;
	margin-right: 10px;
}

.component .image {
	max-width: 500px;

	overflow: hidden;
}

.component .image img {
	display: block;
	object-fit: contain;
	width: auto;
	max-width: 100%;
	height: auto;
	margin: auto;
}

.component .image figcaption {
	color: #fff;
	font-size: 0.9rem;
	max-width: 100%;
	max-height: 100%;
	margin-top: 8px;
}

.component .image small {
	color: #fff;
	font-size: 0.8rem;
	font-style: italic;
	max-width: 100%;
	max-height: 100%;
	margin-top: 8px;
}
