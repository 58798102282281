.issue {
	display: grid;
	grid-template-rows: var(--navbar-height) 1fr;
	grid-template-columns: 0.2fr 0.8fr;
	transition: all ease-out 200ms;
	gap: 0px 0px;
	grid-template-areas:
		'navbar navbar'
		'toc content';
}

.navbar {
	grid-area: navbar;
}

.navbar.mobile {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: var(--navbar-height) 0fr;
	gap: 0px 0px;
	grid-template-areas:
	  "."
	  "navbar-toc";
}

.navbar.mobile.expand {
	grid-template-rows: var(--navbar-height) 1fr;
}

.toc {
	grid-area: toc;
    width: 100%;
	height: 100%;
	overflow: auto;
	border-right: 1px solid #eee;
	list-style-position: inside;
}

.toc > li {
	display: block;
	display: list-item;
	padding: 1.4em;
	font-size: 0.9rem;
	line-height: 1.5;
}

.toc > .item {
	cursor: pointer;
	transition: all ease-out 200ms;
}

.content {
	grid-area: content;
}

.article-swiper article {
	overflow-x: hidden;
	max-height: 100%;
}

.swiper-container {
	width: 100%;
	margin: auto;
	height: 100%;
}

.swiper-container > :first-child {
	height: 100%;
}