.authenticate {
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-between;
	max-width: 350px;
	margin: 0 auto;
}

.authenticate > img {
	height: 7.5vh;
}

.authenticate > input {
	border-radius: 0px;
	border: none;
	border-bottom: solid rgba(24, 24, 24, 0.5) 2px;
	height: 50px;
	width: 90%;
	margin-bottom: 35px;
	font-size: 12pt;
	color: #000;
}

.authenticate > input::placeholder {
	color: rgba(24, 24, 24, 0.9);
	font-size: 13pt;
}

.authenticate > input:invalid {
	border: none;
	border-color: #fff;
	box-shadow: none;
	background: none;
	border-bottom: solid rgba(255, 0, 0, 0.8) 2px;
}

.close-btn {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.close-btn > img {
	cursor: pointer;
}

.authenticate > .header {
	font-family: Georgia, 'Times New Roman', Times, serif;

	font-size: 1.3rem;
	line-height: 32px;
	font-weight: 500;
}

.authenticate > .header > h2 {
	text-align: center;
}

.authenticate > .subheader {
	font-family: Arial, Helvetica, sans-serif;

	font-size: 1.2rem;
	line-height: 22px;
	font-weight: 200;
	margin: 0px 10%;
	text-align: center;
	color: rgb(41, 41, 41);
}

.authenticate > .message {
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 40px;
	width: 100%;
	display: flex;

	justify-content: center;
}

.authenticate > .message > a {
	color: #000;
	text-decoration: none;
	font-weight: 800;
}

.authenticate > .sign-up {
	margin-top: 60px;
	min-height: 40px;
	height: 40px;
	font-size: 16px;
}

.sign-up {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-between;
}

.sign-up > input {
	border-radius: 0px;
	border: none;
	border-bottom: solid rgba(24, 24, 24, 0.5) 2px;
	height: 50px;
	width: 90%;
	margin-bottom: 35px;
	font-size: 12pt;
	color: #000;
}

.sign-up > input::placeholder {
	color: rgba(24, 24, 24, 0.9);
	font-size: 13pt;
}

.sign-up > input:invalid {
	border: none;
	border-color: #fff;
	box-shadow: none;
	background: none;
	border-bottom: solid rgba(255, 0, 0, 0.8) 2px;
}

.sign-up > button {
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-wrap: nowrap;

	cursor: pointer;
	min-height: 50px;
	height: 50px;
	width: 90%;

	margin: 0px 50px;
	padding: 0px 20px;

	font-size: 18px;
	border-radius: 6px;
	background: linear-gradient(145deg, #e6e6e6, #ffffff);
	box-shadow: 6px 6px 12px #d9d9d9, -6px -6px 12px #ffffff;
}

.provider-btn {
	cursor: pointer;
	width: 60%;
	min-height: 40px;
	height: 40px;

	border-radius: 4px;
	border: 1px solid rgb(168, 168, 168);
	margin: 10px 0px;

	display: flex;
	flex-direction: row;
	align-items: center;

	background: rgb(255, 255, 255) none repeat scroll 0% 0%;
}

.provider-btn > :first-child {
	width: 35px;
	height: 35px;
	margin: 5px;

	border-radius: 2px;
	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;
}

.provider-btn > p {
	display: flex;
	width: calc(100% - 40px);
	align-items: center;
	margin-left: 5px;
	letter-spacing: 0.2px;
	font-size: 14px;
	font-weight: 200;
	color: rgb(41, 41, 41);
	font-family: 'Roboto';
}

.provider-btn > :first-child > img {
	width: 18px;
	height: 18px;
}

.authenticate-email {
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-between;
}

.authenticate-email > img {
	height: 7.5vh;
}

.authenticate-email > h2 {
	font-family: Georgia, 'Times New Roman', Times, serif;

	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
	margin-bottom: 50px;
}

.authenticate-email > .subheader {
	font-family: Arial, Helvetica, sans-serif;

	font-size: 16px;
	line-height: 22px;
	margin: 0px 10%;
	text-align: center;
	color: rgb(41, 41, 41);
}

.authenticate-email > label {
	font-family: Arial, Helvetica, sans-serif;

	font-size: 16px;
	margin-bottom: 50px;

	font-weight: 200;
}

.authenticate-email > input {
	background: transparent;
	border: none;
	width: 60%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);

	text-align: center;
}

.authenticate-email > input:focus {
	border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.authenticate-email > input:invalid {
	border: none;
	border-bottom: 2px solid red;
	box-shadow: none;
}

.authenticate-email > .invalid {
	border: none;
	border-bottom: 2px solid red;
	box-shadow: none;
}

.authenticate-email > small {
	color: red;
	margin-top: 8px;
}

.authenticate-email > button {
	margin-top: 40px;
	margin-bottom: 40px;
	border: none;
	background-color: #000;
	border-radius: 6px;
	width: 120px;
	height: 40px;
	color: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.authenticate-email > .back {
	font-family: 'Roboto';
	font-size: 14px;
	cursor: pointer;
}

.authenticate-email.sign-up > img {
	margin-bottom: 20px;
}

.authenticate-email.sign-up > button:disabled {
	opacity: 0.5;
	background: #3d3d3d !important;
}

.authenticate-email.sign-up > input {
	background: transparent;
	width: 80%;
	max-width: 400px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding-left: 24px;
}

.authenticate-email.sign-up > input.invalid {
	border: none;
	border: 2px solid red;
	box-shadow: none;
}

.authenticate-email.sign-up > input::placeholder {
	color: rgba(0, 0, 0, 0.2);
}
