.apple-icon {
	mask: url('../icons/apple-icon.svg');
}

.arrow-up {
	mask: url('../icons/arrow-up.svg');
}

.arrow-down {
	mask: url('../icons/arrow-down.svg');
}

.arrow-right {
	mask: url('../icons/arrow-right.svg');
}

.arrow-left {
	mask: url('../icons/arrow-left.svg');
}

.bookmark {
	mask: url('../icons/bookmark.svg');
}

.bookmark-selected {
	mask: url('../icons/bookmark-selected.svg');
}

.bookmarks {
	mask: url('../icons/bookmarks.svg');
}

.done {
	mask: url('../icons/done.svg');
}

.email {
	mask: url('../icons/email.svg');
}

.font {
	mask: url('../icons/font.svg');
}

.font-selected {
	mask: url('../icons/font-selected.svg');
}

.font-increase {
	mask: url('../icons/font-increase.svg');
}

.font-decrease {
	mask: url('../icons/font-decrease.svg');
}

.toc {
	mask: url('../icons/toc.svg');
}

.horizontal-split {
	mask: url('../icons/horizontal-split.svg');
}

.download {
	mask: url('../icons/download.svg');
}

.downloaded {
	mask: url('../icons/downloaded.svg');
}

.search {
	mask: url('../icons/search.svg');
}

.search-alt {
	mask: url('../icons/search-alt.svg');
}

.cloud-off {
	mask: url('../icons/cloud-off.svg');
}

.home {
	mask: url('../icons/home.svg');
}

.home-selected {
	mask: url('../icons/home-selected.svg');
}

.player {
	mask: url('../icons/player.svg');
}

.article {
	mask: url('../icons/article.svg');
}

.share {
	mask: url('../icons/share.svg');
}

.play-arrow {
	mask: url('../icons/play-arrow.svg');
}

.pause {
	mask: url('../icons/pause.svg');
}

.forward-5 {
	mask: url('../icons/forward-5.svg');
}

.replay-5 {
	mask: url('../icons/replay-5.svg');
}

.cancel {
	mask: url('../icons/cancel.svg');
}

.chevron-left {
	mask: url('../icons/chevron-left.svg');
}

.chevron-right {
	mask: url('../icons/chevron-right.svg');
}

.close {
	mask: url('../icons/close.svg');
}

.cloud {
	mask: url('../icons/cloud.svg');
}

.cloud-queue {
	mask: url('../icons/cloud-queue.svg');
}

.add-link {
	mask: url('../icons/add-link.svg');
}

.attach-email {
	mask: url('../icons/attach-email.svg');
}

.pending {
	mask: url('../icons/pending.svg');
}

.cloud-done {
	mask: url('../icons/cloud-done.svg');
}

.single-page-spread {
	mask: url('../icons/single-page-spread.svg');
}

.double-page-spread {
	mask: url('../icons/double-page-spread.svg');
}

.responsive-mode {
	mask: url('../icons/responsive-mode.svg');
}

.replica-mode {
	mask: url('../icons/replica-mode.svg');
}

.external-link {
	mask: url('../icons/external-link.svg');
}

.printer {
	mask: url('../icons/printer.svg');
}

.zoom-in {
	mask: url('../icons/zoom-in.svg');
}

.icon {
	width: 100%;
	height: 100%;
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
}
