.bottom-bar {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: center;
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	text-align: center;
	z-index: 100;
	padding-bottom: env(safe-area-inset-bottom, var(--safe-area-inset));

	border-radius: 0px;
	background: #ffffff;
	border-top: 1px solid #e0e4ea;
	position: absolute;

	transition: bottom 240ms ease-out;
}

.bottom-bar-icon-container {
	flex-basis: 100%;
	flex: 1;
	align-self: center;
}

.bottom-bar-icon {
	width: 50%;
	margin: auto;
	padding: 6px;
	mask-size: cover;
	display: inline-block;

	border-radius: 0px;
}

.bottom-bar-icon:hover {
	cursor: pointer;
}

/*ICONS*/
.bottom-bar-icon.icon-home > button {
	mask: url('../icons/icon-home.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-home.selected > button {
	mask: url('../icons/icon-home-selected.svg');
}

.bottom-bar-icon.icon-home:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-purchases > button {
	mask: url('../icons/icon-purchases.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}
.bottom-bar-icon.icon-purchases.selected > button {
	mask: url('../icons/icon-purchases-selected.svg');
}

.bottom-bar-icon.icon-purchases:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-bookmarks > button {
	mask: url('../icons/icon-bookmarks.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-bookmarks.selected > button {
	mask: url('../icons/icon-bookmarks-selected.svg');
}

.bottom-bar-icon.icon-bookmarks:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-setting > button {
	mask: url('../icons/icon-setting.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-setting.selected > button {
	mask: url('../icons/icon-setting-selected.svg');
}

.bottom-bar-icon.icon-setting:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-font > button {
	mask: url('../icons/icon-font.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-font:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-font-increase > button {
	mask: url('../icons/icon-font-increase.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-font-increase:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-font-decrease > button {
	mask: url('../icons/icon-font-decrease.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-font-decrease:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-menu > button {
	mask: url('../icons/icon-menu.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-menu.selected > button {
	mask: url('../icons/icon-menu-selected.svg');
}

.bottom-bar-icon.icon-menu:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-reader > button {
	mask: url('../icons/icon-reader-mode.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-reader.selected > button {
	mask: url('../icons/icon-reader-mode-selected.svg');
}

.bottom-bar-icon.icon-reader:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-bookmark > button {
	mask: url('../icons/icon-bookmark.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-bookmark.selected > button {
	mask: url('../icons/icon-bookmark-selected.svg');
}

.bottom-bar-icon.icon-bookmark:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-bookmark.selected > button {
	mask: url('../icons/icon-bookmark-selected.svg');
}

.bottom-bar-icon.icon-feed > button {
	mask: url('../icons/icon-feed.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-feed.selected > button {
	mask: url('../icons/icon-feed-selected.svg');
}

.bottom-bar-icon.icon-feed:hover button {
	background: var(--hover-color);
}

.bottom-bar-icon.icon-feed.selected > button {
	mask: url('../icons/icon-feed-selected.svg');
}

.bottom-bar-icon > button {
	mask-repeat: no-repeat !important;
}

.font-size-option-holder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: absolute;
	height: var(--bottom-bar-height);
	bottom: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);

	width: 100%;
	background: #ffffff;
	box-shadow: 1px -1px 3px #b5b5b5, -1px 1px 3px #ffffff;

	transition: bottom 400ms ease-out;
	z-index: 100;
}

footer.not-display {
	--not-display-bottom-bar-height: calc(var(--bottom-bar-height) * -1);
	--not-display-safe-inset: calc(
		env(safe-area-inset-bottom, var(--safe-area-inset)) * -1
	);
	bottom: calc(
		var(--not-display-bottom-bar-height) + var(--not-display-safe-inset)
	);
}

.font-slider-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	margin-left: 20px;
	margin-right: 20px;
}

.font-icon-container {
	display: flex;
	flex-direction: column;
	width: 60px;
}

@media screen and (min-width: 767px) {
	.bottom-bar {
		display: none !important;
	}
}