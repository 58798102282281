.container {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: var(--navbar-height) calc(100% - var(--navbar-height));
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.container > nav {
	width: 100%;
	height: var(--navbar-height);
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. . .';

	/*-webkit-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);*/

	/*0 1px 2px rgba(0,0,0,.18),0 1px 1px rgba(0,0,0,.03)!important*/

	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.container > nav > :first-child {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	padding-left: 15px;
}

.container > nav > :first-child > button {
	mask: url('../../components/navbar/arrow-left.svg');
	height: 24px;
	width: 24px;
	background: #000;
}

.container > nav > .logo {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.container > nav > .logo > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.container > main {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 0.3fr 0.7fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.items {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	border-right: 1px solid #eee;
	transition: width 2s;
}

.item {
	width: 100%;
	cursor: pointer;
	height: 15vh;
	border-bottom: 1px solid #eee;
	display: grid;
	grid-template-columns: 30% 70%;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
	padding: 0px 10px;
}

.item.no-image {
	grid-template-columns: 0% 100%;
	padding: 0px 20px;
}

.item > .image {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.item > .image > :first-child {
	background: #c4c4c4;
	border-radius: 4px;
	border: 1px solid rgba(196, 196, 196, 0.8);
	overflow: hidden;
	width: 80%;
	height: 60%;
	object-fit: cover;
}

.item > .content {
	width: 100%;
	height: 80%;
	max-height: 80%;
	margin: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.item > .content > .title {
	min-height: 20px;
	width: 100%;
	overflow: hidden;
}

.item > .content > .title > h3 {
	width: 100%;
	margin: 0;
	text-overflow: ellipsis;
	white-space: pre;
	overflow: hidden;
	font-size: 0.9rem;
	color: #3d3d3d;
}

.item > .content > small {
	margin: 0;
	font-size: 0.7rem;
	font-weight: bold;
	color: #888;
}

.item > .content > .description {
	margin: 0;
	margin-top: 6px;
	width: 100%;
	display: inline;
	font-size: 0.75rem;
	text-transform: lowercase;
	line-height: 1.6;
	font-weight: normal !important;
	color: #777;
	overflow: hidden;
}

.item > .content > .description > p {
	display: inline;
	margin-right: 1px;
	text-align: justify;
}
.item > .content > .description > p::first-letter {
	text-transform: uppercase;
}

.item > .content > .description > * {
	margin: 0;
}

.item > .content > .description > * > b {
	margin: 0;
	font-weight: normal;
}

.item > .content > .description > * > b::first-letter {
	text-transform: uppercase;
}

.item > .content > .description > * > img {
	display: hidden;
}

.article-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
}

.article-container > article {
	width: 84%;
	height: 100%;
	margin-bottom: 20px;
	padding-top: 40px;
	position: relative;
}

.article-container > article > h1 {
	margin: 0;
	margin-bottom: 6px;
}

.article-container > article > small {
	color: #888;
	display: block;
	font-size: 0.9rem;
	margin-bottom: 20px;
}

.article-container > article > section {
	width: 100%;
}

.article-container > article > section > p {
	line-height: 1.7rem;
	font-size: 1.1rem;
	color: #3d3d3d;
	text-align: justify;
	/*word-spacing: 0.05rem;*/
	margin-bottom: 1.1em;
}

.article-container > article > section > p > b {
	color: #000;
	display: block;
	text-align: initial;
}

.article-container > article > section > p > img {
	max-width: 100%;
	/*width: 100%;*/
	object-fit: cover;
	margin: auto;
	display: none !important;
}

.article-container > article > .link {
	border-top: 1px solid #c4c4c4;
	height: 20px;
	width: 100%;
	padding-top: 20px;
	margin-bottom: 50px;
	font-weight: bold;
	color: #0074d9;
	text-align: center;
}

.article-container > article > .thumbnail {
	width: 120px;
	height: 164px;
	position: fixed;
	cursor: pointer;
	bottom: 0;
	right: 20px;
	background: white;
	padding: 5px;

	border: 1px solid #eee;
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
	margin-bottom: 20px;
	overflow: hidden;
	transition: box-shadow 150ms ease;
}

.article-container > article > .thumbnail:hover {
	box-shadow: 0 7px 7px rgb(0 0 0 / 12%), 0 7px 7px rgb(0 0 0 / 12%),
		0 5px 5px rgb(0 0 0 / 12%);
}

.article-container > article > .thumbnail > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 767px) {
	.container > main {
		grid-template-columns: 1fr 0fr;
	}
	.container > main.selected {
		grid-template-columns: 0fr 1fr;
	}
	.item {
		grid-template-columns: 30% 70%;
		height: 15vh;
	}

	.article-container > article {
		width: 88%;
	}
}

@media screen and (min-width: 769px) {
	.container > main {
		grid-template-columns: 0.35fr 0.65fr;
	}
	.item {
		height: 15vh;
		grid-template-columns: 30% 70%;
	}
}

@media screen and (min-width: 1024px) {
	.item {
		height: 100px;
	}
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
	.container > main {
		grid-template-columns: 0.35fr 0.65fr;
	}
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
	.container > main {
		grid-template-columns: 0.35fr 0.65fr;
	}
}
