.navbar {
	position: absolute;
	display: flex;
	flex-direction: column;

	width: 100vw;
	font-size: 16px;
	z-index: 250;

	/*-webkit-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);*/

	/*0 1px 2px rgba(0,0,0,.18),0 1px 1px rgba(0,0,0,.03)!important*/

	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;

	transition: height 600ms ease-out;
	transition: margin 400ms ease-in;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navbar > :first-child {
	align-self: initial;
	display: flex;

	/*justify-content:space-between;*/

	/*FIXME This change is only for 5thwave*/
	justify-content: center;
	position: relative;
}

.navbar > .toc {
	width: 100vw;

	height: 0;
	transition: height 400ms ease-out;
	overflow: auto;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navbar > .toc.open {
	height: calc(100vh - var(--navbar-height));
	transition: height 400ms ease-in;
	list-style-position: inside;
}

.navbar > .toc > li {
	cursor: pointer;
	margin: 0 auto;
	width: 95%;
	text-align: center;
	height: 56px;
	align-items: center;
	text-transform: uppercase;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	display: list-item;
	padding-top: 1em;
}

.navbar > .toc > * > * {
	display: table;
	height: 100%;
}

.navbar.not-display {
	margin-top: calc(var(--navbar-height) * -1);
}

.navbar-left {
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	height: 100%;
	text-align: left;
	align-items: center;
	position: absolute;
}

.navbar-title {
	text-align: center;
	align-self: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.navbar-logo {
	text-align: center;
	align-self: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navbar-logo > * > img {
	cursor: pointer;
}

.navbar-right {
	display: flex;
	flex-direction: row;
	padding-right: 10px;
	text-align: right;
	align-self: center;
	right: 0;
	height: 30px;
}

.navbar-right > span {
	margin-left: 6px;
}

.navbar-right > span > img {
	padding: 5px;
}

.language-container {
	width: 100vw;
	height: 100vh;
	z-index: 999999999;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;

	display: flex;
	align-items: center;
	justify-content: center;
}

.language-container > .language-modal {
	width: 90vw;
	height: 70vh;
	max-width: 500px;
	max-height: 70vw;
	background: #fff;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.language-modal > .header {
	width: 100%;
	height: 56px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. . .';
	border-bottom: 1px solid #c4c4c4;
}

.language-modal > .header > .title {
	display: flex;
	justify-content: center;
	align-items: center;
}

.language-modal > .header > .close {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.language-modal > .header > .close > button {
	mask: url('./icon-close.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	margin-right: 15%;
}

.language-modal > .content {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background: #fafafa;

	display: flex;
	flex-direction: column;
}

.language-modal > .content > ul {
	list-style-type: none;
	width: 100%;
	padding: 0px;
}

.language-modal > .content > ul > :first-child {
	border-top: 1px solid rgb(227, 227, 227);
}

.language-modal > .content > ul > li {
	width: 100%;
	background: #fff;
	border-bottom: 1px solid rgb(227, 227, 227);
	height: 42px;
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
}

.icon-offline {
	mask: url('./icon-offline.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.icon-check {
	mask: url('./icon-check.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.icon-language {
	mask: url('./icon-language.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.icon-menu {
	display: none;
	mask: url('./icon-menu.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.icon-menu.selected {
	mask: url('./icon-menu-selected.svg');
}

.icon-player {
	mask: url('./icon-player.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.icon-player.selected {
	mask: url('./icon-player-selected.svg');
}

.icon-download {
	mask: url('./icon-download.svg');
	margin: auto;
	height: 30px;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;

	width: 30px;
}
.icon-download.selected {
	mask: url('./icon-download-selected.svg');
	margin: auto;
	height: 30px;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;

	width: 30px;
}

.nav-bar-icon {
	width: 50px;
	margin: auto;
	padding: 6px;
	-webkit-mask-size: cover;
	mask-size: cover;
	display: inline-block;
}

.nav-bar-icon.icon-back > button {
	mask: url('./arrow-left.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.icon-play > button {
	mask: url('./icon-play.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.setting-options {
	min-height: var(--navbar-height);
	display: flex;
}

@media screen and (max-width: 767px) {
	.setting-options {
		min-height: 0;
		display: none;
	}
	.icon-menu {
		display: initial;
	}
}

.setting-options > div {
	min-height: var(--navbar-height);
	width: var(--navbar-height);
	-webkit-mask-size: cover;
	mask-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.share-button {
	mask: url('../icons/icon-share.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.reader-mode-button {
	mask: url('../icons/icon-reader-mode.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}
.reader-mode-button.selected {
	mask: url('../icons/icon-reader-mode-selected.svg');
}

.image-mode-button {
	mask: url('../icons/icon-image-mode.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}
.image-mode-button.selected {
	mask: url('../icons/icon-image-mode-selected.svg');
}

.bookmark-button {
	mask: url('../icons/icon-bookmark.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}
.bookmark-button.selected {
	mask: url('../icons/icon-bookmark-selected.svg');
}

.font-size-button {
	mask: url('../icons/icon-font.svg');
	margin: auto;
	height: 24px;
	width: 24px;
}

.font-size-button.selected {
	mask: url('../icons/icon-font-selected.svg');
}

.playbar {
	position: absolute;
	display: flex;
	flex-direction: row;
	margin-top: var(--navbar-height);
	min-height: var(--navbar-height);

	width: 100vw;
	font-size: 16px;
	z-index: 250;
	border-top: 1px solid black;

	background-color: black;

	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.playbar > div {
	height: var(--navbar-height);
	display: flex;
	justify-content: center;
	align-items: center;
}

.playbar > :first-child {
	width: 20%;
}

.playbar > :nth-child(2) {
	width: 60%;
	/*background: green;*/
}

.playbar > :last-child {
	width: 20%;
}
