.settings {
	width: 100%;
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
	padding-top: var(--navbar-height);

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.settings {
	background: #f5f5f5;
}

.settings > div {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.settings > div > div {
	width: 100%;
}

.settings > div > a {
	text-decoration: none;
	color: inherit;
}

.settings > div > .group > * {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	color: #3d3d3d;
	cursor: pointer;
}

.settings > div > .group > a {
	text-decoration: none;
	color: inherit;
}

/*Stylesheet for the storage section*/

.settings-storage {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	background: #f4f4f4;
	padding-top: calc(var(--navbar-height) + 20px);
	
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.settings-storage > div {
	width: 100%;
}

.settings-storage > .group > div {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	color: #3d3d3d;
	cursor: pointer;
}

.settings > div > :first-child {
	height: 60px;
	color: #3d3d3d;
	background: #fff;
	width: 100%;
	padding-left: 20px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	margin-top: 10px;
}

/*Stylesheet for the subscription section*/
.settings-subscription {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: calc(var(--navbar-height) + 20px);
	background: #f4f4f4;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.settings-subscription > div {
	background: #fff;
	height: 100px;
	margin-bottom: 1px;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	color: #3d3d3d;
	cursor: pointer;
}

.settings-language {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	background: #f4f4f4;
	padding-top: calc(var(--navbar-height) + 20px);

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.settings-language > div {
	width: 100%;
}

.settings-language > .group > div {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	color: #3d3d3d;
	cursor: pointer;
	justify-content: space-between;
}

.settings-about {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	background: #f4f4f4;
	overflow-y: scroll;
	padding-top: calc(var(--navbar-height) + 20px);

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.settings-about > div {
	width: 100%;
}

.settings-about > .group > div {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	color: #3d3d3d;
	cursor: pointer;
	justify-content: space-between;
}

.settings-about > :last-of-type.group {
	margin-bottom: 30px;
}

.dialog-overlay {
	width: 100%;
	height: 100%;
	z-index: 9999;
	position: fixed;
	top: 0;
	float: left;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
}

.admin-dialog-container {
	max-width: 90%;
	min-width: 350px;
	width: 450px;
	height: 450px;
	background: #fff;
	border-radius: 4px;
	z-index: 99999;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.2fr 1.8fr;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.admin-dialog-container > .topbar {
	width: 100%;
	height: var(--navbar-height);
	display: grid;
	grid-template-columns: 0.3fr 2.4fr 0.3fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas:
	  ". . .";
	border-bottom: 1px solid #eee;
}

.admin-dialog-container > .topbar > h4 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.admin-dialog-container > .topbar > button {
	mask: url('./icons/x-icon.svg');
	height: 24px;
	width: 24px;
	margin-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	background: #000;
	right: 0;
}

.admin-dialog-container > .content {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1.7fr 0.3fr;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.admin-dialog-container > .content > div {
	padding: 0px 16px;
}

.admin-dialog-container > .content > div > .error {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	min-height: 30px;
	width: 100%;
	padding: 10px 10px;
	color: #fff;
	margin: 0px -16px;
	background: red;
	font-size: 0.8rem;
	transition: opacity ease-out 200ms;
	opacity: 1;
}

.admin-dialog-container > .content > div > .error.hide {
	opacity: 0;
}

.admin-dialog-container > .content > :first-child {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.admin-dialog-container > .content > :first-child > img {
	width: 90px;
	height: 90px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 56px;
}

.admin-dialog-container > .content > :first-child > input {
	margin-bottom: 16px;
	height: 45px;
	padding-left: 8px;
	border: 1px solid #eee;
	border-radius: 4px;
}

.admin-dialog-container > .content > :last-child {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #eee;
}

.admin-dialog-container > .content > :last-child > button {
	width: 100%;
	background: #000;
	color: #fff;
	height: 32px;
	border: none;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.admin-dialog-container > .content > :last-child > button:disabled {
	background: rgba(0, 0, 0, 0.5);
	cursor: not-allowed;
}

@media screen and (min-width: 769px) {
	.settings {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.container {
		width: 100%;
	}

	.settings > div {
		width: calc(100% - var(--bottom-bar-height));
		margin-left: var(--bottom-bar-height);
	}
}
