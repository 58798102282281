.loading-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ripple div {
	position: absolute;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

.circle-loading {
	border: 4px solid #f3f3f3; /* Light grey */
	border-top: 4px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

.lds-circle {
	display: inline-block;
	transform: translateZ(1px);
}
.lds-circle > div {
	display: inline-block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	background: #fff;
	animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(1800deg);
		animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		transform: rotateY(3600deg);
	}
}

.lds-rectangle-pulse {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-rectangle-pulse div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #fff;
	animation: lds-rectangle-pulse 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-rectangle-pulse div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}
.lds-rectangle-pulse div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}
.lds-rectangle-pulse div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}
@keyframes lds-rectangle-pulse {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}
.lds-roller div:after {
	content: ' ';
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fff;
	margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
}
.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
}
.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
}
.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
}
.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
}
.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
}
.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
}
.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
}
@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.lds-default {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-default div {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #fff;
	border-radius: 50%;
	animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
	animation-delay: 0s;
	top: 37px;
	left: 66px;
}
.lds-default div:nth-child(2) {
	animation-delay: -0.1s;
	top: 22px;
	left: 62px;
}
.lds-default div:nth-child(3) {
	animation-delay: -0.2s;
	top: 11px;
	left: 52px;
}
.lds-default div:nth-child(4) {
	animation-delay: -0.3s;
	top: 7px;
	left: 37px;
}
.lds-default div:nth-child(5) {
	animation-delay: -0.4s;
	top: 11px;
	left: 22px;
}
.lds-default div:nth-child(6) {
	animation-delay: -0.5s;
	top: 22px;
	left: 11px;
}
.lds-default div:nth-child(7) {
	animation-delay: -0.6s;
	top: 37px;
	left: 7px;
}
.lds-default div:nth-child(8) {
	animation-delay: -0.7s;
	top: 52px;
	left: 11px;
}
.lds-default div:nth-child(9) {
	animation-delay: -0.8s;
	top: 62px;
	left: 22px;
}
.lds-default div:nth-child(10) {
	animation-delay: -0.9s;
	top: 66px;
	left: 37px;
}
.lds-default div:nth-child(11) {
	animation-delay: -1s;
	top: 62px;
	left: 52px;
}
.lds-default div:nth-child(12) {
	animation-delay: -1.1s;
	top: 52px;
	left: 62px;
}
@keyframes lds-default {
	0%,
	20%,
	80%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
}

.lds-grid {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-grid div {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #fff;
	animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
	top: 8px;
	left: 8px;
	animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
	top: 8px;
	left: 32px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
	top: 8px;
	left: 56px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
	top: 32px;
	left: 8px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
	top: 32px;
	left: 32px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
	top: 32px;
	left: 56px;
	animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
	top: 56px;
	left: 8px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
	top: 56px;
	left: 32px;
	animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
	top: 56px;
	left: 56px;
	animation-delay: -1.6s;
}
@keyframes lds-grid {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}

.lds-hourglass {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-hourglass:after {
	content: ' ';
	display: block;
	border-radius: 50%;
	width: 0;
	height: 0;
	margin: 8px;
	box-sizing: border-box;
	border: 32px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
}

.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: #fff;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
