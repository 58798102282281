.sidebar {
	background: #fff;
	width: var(--bottom-bar-height);
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
}

.sidebar > div {
	width: 100%;
	height: calc(
		100vh / 2 - var(--bottom-bar-height) / 2 -
			env(safe-area-inset-bottom, var(--safe-area-inset)) / 2
	);
}

.sidebar > :first-child {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.sidebar > :nth-child(2) {
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: calc(var(--bottom-bar-height) + 20px);
}

/*@media screen and (min-height: 767px) {
    .sidebar > :nth-child(2) {
        padding-bottom: 0;
    }
}*/

/*Varios hijos de tu class hasta que llegues al select que quieres*/
.your-class > div > div > div {
	border: 1ox;
}

.sidebar-icon {
	width: 100%;
	margin: auto;
	padding: 6px;
	mask-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sidebar-icon > * {
	margin: auto;
}

.sidebar-icon:hover {
	cursor: pointer;
}

.sidebar-icon-container {
	width: 100%;
	margin-top: 24px;
}

/*ICONS*/
.sidebar-icon.icon-home > button {
	mask: url('../icons/icon-home.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-home.selected > button {
	mask: url('../icons/icon-home-selected.svg');
}

.sidebar-icon.icon-home:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-feed > button {
	mask: url('../icons/icon-feed.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-feed.selected > button {
	mask: url('../icons/icon-feed-selected.svg');
}

.sidebar-icon.icon-feed:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-purchases > button {
	mask: url('../icons/icon-purchases.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}
.sidebar-icon.icon-purchases.selected > button {
	mask: url('../icons/icon-purchases-selected.svg');
}

.sidebar-icon.icon-purchases:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-bookmarks > button {
	mask: url('../icons/icon-bookmarks.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-bookmarks.selected > button {
	mask: url('../icons/icon-bookmarks-selected.svg');
}

.sidebar-icon.icon-bookmarks:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-setting > button {
	mask: url('../icons/icon-setting.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-setting.selected > button {
	mask: url('../icons/icon-setting-selected.svg');
}

.sidebar-icon.icon-setting:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-font > button {
	mask: url('../icons/icon-font.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-font:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-font-increase > button {
	mask: url('../icons/icon-font-increase.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-font-increase:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-font-decrease > button {
	mask: url('../icons/icon-font-decrease.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-font-decrease:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-menu > button {
	mask: url('../icons/icon-menu.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-menu.selected > button {
	mask: url('../icons/icon-menu-selected.svg');
}

.sidebar-icon.icon-menu:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-reader > button {
	mask: url('../icons/icon-reader-mode.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-reader.selected > button {
	mask: url('../icons/icon-reader-mode-selected.svg');
}

.sidebar-icon.icon-reader:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-bookmark > button {
	mask: url('../icons/icon-bookmark.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.sidebar-icon.icon-bookmark.selected > button {
	mask: url('../icons/icon-bookmark-selected.svg');
}

.sidebar-icon.icon-bookmark:hover button {
	background: var(--hover-color);
}

.sidebar-icon.icon-bookmark.selected > button {
	mask: url('../icons/icon-bookmark-selected.svg');
}

.sidebar-icon > button {
	mask-repeat: no-repeat !important;
}

@media screen and (min-width: 767px) {
	.sidebar {
		display: flex;
		position: fixed;
		left: 0;
		z-index: 500;
	}
}

@media screen and (max-width: 767px) {
	.sidebar {
		display: none;
	}
}
