.home {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);

	background: #fafafa;
	padding-top: var(--navbar-height);
}

.thumbnail-item {
	display: block;
	position: relative;
	cursor: pointer;
	height: 100%;
	width: var(--thumbnail-width);
	background: #fafafa;
	-webkit-box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
	-moz-box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
	box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.thumbnail-item:after {
	z-index: -1;
	position: absolute;
	content: '';
	bottom: 15px;
	right: 10px;
	left: auto;
	width: 50%;
	top: 80%;
	max-width: 300px;
	background: #777;
	-webkit-box-shadow: 0 15px 10px #777;
	-moz-box-shadow: 0 15px 10px #777;
	box-shadow: 0 15px 10px #777;
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	transform: rotate(3deg);
}

.thumbnail-item img {
	width: 100%;
}

.thumbnail-item > .issue-downloaded {
	position: absolute;
	bottom: 0px;
	right: 0px;
	margin-right: 5px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.thumbnail-item > .issue-downloaded > div {
	mask: url('./icons/icon-smartphone-2.svg');
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	margin: auto;
	height: 24px;
	width: 24px;
	background-color: black;
	-webkit-box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 0.75);
}

.corner-download {
	/*Positioning*/
	right: 0;
	bottom: 0;
	float: right;
	position: absolute;
	background: #eaeaea;
	border: none;

	/*Size*/
	width: 110px;
	height: 50px;

	/*Text*/
	color: #fff;
	font-weight: bold;
	font-size: 11pt;

	/*Center text*/
	display: flex;
	align-items: center;
	justify-content: center;

	/*Rotate the ribbon*/
	transform: translateX(45px) translateY(15px) rotate(-45deg);
	transition: box-shadow ease-out 200ms;
}

.corner-download:hover {
	box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
}

.corner-download div {
	transform: rotate(45deg) translateY(-8px) translateX(-5px);
}

.corner-ribbon {
	/*Positioning*/
	right: 0;
	float: right;
	position: absolute;
	background: #e43;

	/*Size*/
	width: 110px;
	height: 20px;

	/*Text*/
	color: #fff;
	font-weight: bold;
	font-size: 11pt;

	/*Center text*/
	display: flex;
	align-items: center;
	justify-content: center;

	/*Rotate the ribbon*/
	transform: translateX(30px) translateY(20px) rotate(45deg);
	box-shadow: 6px 7px 11px -8px rgba(0, 0, 0, 0.75);
}

.corner-ribbon.paid {
	font-size: 9pt;
	background: blue;
}

.netflix-layout-container {
	height: calc(100% + var(--navbar-height) + 20px);
	overflow: hidden;
	max-height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.netflix-layout-container-single {
	height: calc(100% + var(--navbar-height) + 20px);
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '.';
}

.netflix-banner {
	height: 50vh;
	width: 100%;

	position: relative;
	/*margin-bottom: 50px;*/
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;

	overflow: hidden;
	/*-webkit-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.75);*/
	padding-top: 10px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.netflix-banner > .cover-item {
	display: block;
	position: relative;
	z-index: 0;
	cursor: pointer;

	overflow: hidden;

	margin: auto;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.netflix-banner > .cover-item img {
	background: #fff;
	border: 1px solid #e0e4ea;
}

.cover-item:after {
	z-index: -1;
	position: absolute;
	content: '';
	bottom: 15px;
	right: 10px;
	left: auto;
	width: 50%;
	top: 80%;
	max-width: 400px;
}

.netflix-banner .cover-item a {
	height: 100%;
	max-width: 100%;
}

.netflix-banner .cover-item img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.netflix-banner > hr {
	border: 1px solid #e0e4ea;
	margin-top: 20px;
	margin-bottom: 30px;
	width: 100%;
}

.netflix-banner > .logo {
	width: 70vw;
	max-width: 70vw;
	z-index: 0;
	margin: auto;
}

.cover-item > .description {
	text-align: center;
	margin-top: 3px;
	font-size: 10pt;
	line-height: 11pt;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.netflix-banner > .archive {
	z-index: 0;
	display: flex;
	align-items: flex-start;
	color: #777;
	font-size: 10pt;
}

.netflix-banner > .logo > img {
	max-width: 70vw;
}

.cover-blur-netflix {
	z-index: 0;
	position: absolute;

	filter: blur(4px);
	-webkit-filter: blur(4px);

	/* Full height */
	width: 100%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.netflix-item {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: baseline;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.netflix-item > :first-child {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.netflix-item > :first-child > .description {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	font-size: 10pt;
	overflow: hidden;
	text-align: center;
}

.netflix-list {
	height: 100%;
	width: 100%;
	display: grid;
	overflow: initial;
	padding: 3%;
}

.netflix-banner > .cover-item > .corner-ribbon {
	top: -5px;
}

.feed-list {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 35vh;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.feed-group-list {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0px 30px;
	padding-top: 10px;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.feed-group {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 50px 360px;
	gap: 0px 0px;
	margin-top: 30px;
	grid-template-areas:
		'.'
		'.';
}

.feed-group > :first-child {
	display: flex;
	height: 100%;
	align-items: center;
}

.feed-group > :first-child > h3 {
	height: auto;
	font-size: 1.3rem;
	font-weight: 500;
	color: #222;
	margin-left: 12px;
}

.feed-group > :last-child {
	display: flex;
	overflow-x: auto;
}

.feed {
	width: 250px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: 12px;
	padding-right: 12px;
}

.feed > .image {
	width: 225px;
	height: 300px;
	border-radius: 12px;
	overflow: hidden;
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	transition: box-shadow 200ms ease-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.feed > .image > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	padding: 15px;
	transition: transform 200ms ease-out;
}

.feed > .image:hover {
	box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.feed > .image:hover > img {
	transform: scale(1.08);
}

.feed > .name {
	margin-top: 8px;
	min-height: 30px;
}

.feed > .name > h3 {
	margin: 0;
	font-weight: 400;
	font-size: 0.9rem;
	color: #3d3d3d;
}

@media screen and (min-width: 769px) {
	.home {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: hidden;
	}

	.home > footer {
		display: none !important;
	}

	.home > .netflix-layout-container {
		overflow: hidden;
	}

	.netflix-layout-container {
		display: grid;
		grid-template-columns: 0.7fr 0.3fr;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas: '. .';
	}

	.netflix-banner {
		height: 100%;
		width: calc(100% - var(--navbar-height));
		margin-left: calc(var(--navbar-height) - 15px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		-webkit-box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		-moz-box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		background-color: #f4f4f4;
	}

	.netflix-banner > .cover-item {
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.cover-item > .description {
		margin-top: 5%;
	}

	/*.cover-item > img {
		width: 95%;
	}*/

	.cover-blur-netflix {
		filter: blur(4px);
		-webkit-filter: blur(4px);
		width: auto;
		height: 100%;
	}

	.cover-blur-netflix > img {
		display: none;
		height: 100%;
	}

	.netflix-list {
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;

		flex-direction: row;
		flex-flow: wrap;
		width: 100%;
	}

	.netflix-item {
		width: 100%;
		max-width: 300px;
		height: auto;
		margin: 20px 0px;
	}

	.feed-list {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 60vh;
		gap: 0px 0px 0px;
		grid-template-areas: '. . .';
	}

	.feed-group-list {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
	}
}

@media screen and (min-width: 992px) {
	.home > .netflix-layout-container {
		overflow: hidden;
		width: calc(100% - var(--bottom-bar-height));
		margin-left: var(--bottom-bar-height);
	}

	.netflix-layout-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas: '. .';
		overflow: hidden;
		max-height: 100%;
	}

	.netflix-banner {
		height: 100%;
		width: calc(100%);
		margin-left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;

		-webkit-box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		-moz-box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		background-color: #f4f4f4;
	}

	.netflix-banner > .cover-item {
		max-width: 40vw;
		min-height: 30vh;
		min-width: 200px;
		margin: auto;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.netflix-banner > .cover-item > img {
		width: auto;
		max-width: 90%;
		max-height: 90%;
	}

	/*.cover-item > img {
		width: 95%;
	}*/

	.cover-blur-netflix {
		filter: blur(4px);
		-webkit-filter: blur(4px);
		width: auto;
		height: 100%;
	}

	.cover-blur-netflix > img {
		display: none;
		height: 100%;
	}

	.netflix-list {
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;

		flex-direction: row;
		flex-flow: wrap;
		justify-content: space-around;
		width: 100%;
	}

	.cover-item > .description {
		margin-top: 5%;
	}

	.netflix-item {
		width: 100%;
		max-width: 50%;
		height: auto;
		margin: 20px 0px;
	}
}

@media screen and (min-width: 1200px) {
	.netflix-banner > .cover-item {
		min-height: 70vh;
		margin: auto;
		max-width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.netflix-list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 40px 0px;
		grid-template-areas: '. . .';
	}

	.netflix-item {
		width: 100%;
		max-width: 100%;
		height: 100%;
	}

	.feed-list {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 60vh;
		gap: 0px 0px 0px 0px;
		grid-template-areas: '. . .';
	}

	.feed-group-list {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
	}

	.netflix-item:nth-child(3n + 1):nth-last-child(-n + 3),
	.netflix-item:nth-child(3n + 1):nth-last-child(-n + 3) ~ .netflix-item {
		padding-bottom: 20px;
	}
}

@media screen and (max-width: 767px) {
	.feed > .name {
		min-width: 90%;
	}

	.home {
		height: calc(
			100vh - var(--bottom-bar-height) -
				env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.netflix-banner {
		height: 460px;
		justify-content: center;
	}

	.cover-blur-netflix > img {
		width: 100%;
		display: none;
	}

	.netflix-banner > .cover-item > img {
		height: 100%;
		width: 100%;
	}

	.netflix-banner > .cover-item {
		margin-left: 0;
		height: 400px;
		min-width: 300px;
		max-width: 300px;
		margin: auto;
	}

	.netflix-list {
		overflow: initial;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: calc(var(--bottom-bar-height) * 2.5);
	}

	.netflix-item {
		width: 100%;
		max-width: 50%;
		height: auto;
		margin: 20px 0px;
	}

	.thumbnail-item {
		max-width: 100%;
	}
	.thumbnail-item > img {
		min-height: 190px;
	}

	.netflix-layout-container {
		overflow-x: hidden;
		overflow-y: auto;
	}

	.home > footer {
		display: flex !important;
	}

	.netflix-layout-container-single > .netflix-banner {
		height: 100%;
	}

	.netflix-layout-container-single > .netflix-banner > .cover-blur-netflix {
		display: none;
	}

	.feed-group-list {
		padding: 0px;
		padding-left: 10px;
	}

	.feed-group-list > :last-child > :last-child {
		margin-bottom: 50px;
	}

	.feed-group {
		grid-template-rows: 50px 360px;
		gap: 0px 0px;
		margin-top: 30px;
	}

	.feed-group > :last-child {
		display: flex;
		overflow-x: auto;
	}

	.feed {
		width: 270px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-left: 20px;
		padding-right: 20px;
	}
}
