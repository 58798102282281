.empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.empty-state-container > div {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.empty-state-image {
    padding-top: 50px;
    text-align: center;
    height: 150px;
    margin: auto;
    width: var(--empty-state-width);
    background-color: gray;

    -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
    margin-bottom: 20px;
}

.empty-state-image.bookmark {
    mask: url('./icon-empty-bookmark.svg');
    mask-size: var(--empty-state-width) auto;
    mask-repeat: no-repeat;
}

.empty-state-image.publication {
    mask: url('./icon-empty-publication.svg');
    mask-size: var(--empty-state-width) auto;
    mask-repeat: no-repeat;
}

.empty-state-image.purchases {
    mask: url('./icon-empty-purchase.svg');
    mask-size: var(--empty-state-width) auto;
    mask-repeat: no-repeat;
}

.empty-state-image.issue {
    mask: url('./icon-empty-issue.svg');
    mask-size: var(--empty-state-width) auto;
    mask-repeat: no-repeat;
}

.empty-state-image.feeds {
    mask: url('./icon-empty-feeds.svg');
    mask-size: var(--empty-state-width) auto;
    mask-repeat: no-repeat;
}

.empty-state-image > img {
    stroke: red;
}

.empty-state-content {
    color: gray;
    text-align: center;
}

.empty-state-content > h3 {
    font-weight: 500;
    font-size: 1.05rem;
    margin-bottom: 5px;
}