.purchase-issue {
	height: 100%;
	width: 100vw;

	overflow: auto;

	display: flex;
	flex-direction: column;
}

.purchase-issue-loading {
	height: 100vh;
	width: 100vw;

	overflow: auto;

	display: flex;
	flex-direction: column;
}

.cover-container {
	position: relative;
	height: 60%;
	overflow: hidden;
}

.cover-blur {
	z-index: -1;
	position: absolute;
	filter: blur(8px);
	-webkit-filter: blur(8px);

	/* Full height */
	width: 100%;
}

.cover-container > div {
	display: inline-block;
	width: 100%;
	height: 100%;
}

.cover-container > div {
	display: flex;
	width: 100%;
	height: 100%;
}

.checkout-container {
	z-index: 999;
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.checkout {
	width: 90%;
	max-width: 500px;
	padding: 20px;
	background-color: rgb(247, 250, 252);
	border-radius: 8px;
}

.checkout > label {
	margin-bottom: 20px;
}

.checkout > label > h1 {
	font-size: 16pt;
	margin-bottom: 10px;
}

.checkout > button {
	margin-top: 20px;
	border: none;
	font-weight: bold;
	min-height: 50px;
	height: 50px;
	background: black;
	color: white;
	width: 100%;
	border-radius: 6px;
}

.cover {
	width: 30vh;
	margin: auto;
	border-radius: 3px;

	background-color: transparent;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;

	-webkit-box-shadow: 3px 4px 28px -1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 3px 4px 28px -1px rgba(0, 0, 0, 0.75);
	box-shadow: 3px 4px 28px -1px rgba(0, 0, 0, 0.75);
}

.cover-blur > img {
	height: 100%;
	width: 100%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.purchase-issue > .details {
	height: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.purchase-button-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
}

.purchase-issue > .details > h1 {
	font-size: 15pt;
	margin-top: 20px;
	margin-bottom: 10px;
}

.purchase-issue > .details > p {
	font-size: 11pt;
	margin: 10px 0px;
	color: gray;
}

.purchase-issue > .details > :last-child {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
	width: 80%;
	max-width: 300px;
	margin-top: 20px;
}

.purchase-issue > .details > :last-child > div,
.purchase-issue > .details > :last-child > a {
	width: 100%;
	margin-bottom: 20px;
}

.purchase-issue > .details > :last-child > div > :first-child,
.purchase-issue > .details > :last-child > a > :first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	flex-wrap: nowrap;
	text-decoration: none;
	color: #fff;

	cursor: pointer;
	min-height: 50px;
	height: 50px;

	font-size: 18px;
	border-radius: 6px;

	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.purchase-button > :first-child {
	background: black;
}

.subscribe-button > :first-child {
	background: #3d3d3d;
}

.subscription {
	height: 100vh;
	width: 100vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 60px;
}

.subscription > img {
	height: 7.5vh;
}

.subscription > .header {
	width: 90%;
	display: flex;

	align-items: center;
	justify-content: center;

	font-family: Georgia, 'Times New Roman', Times, serif;
	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
}

.subscription > .subheader {
	width: 90%;
	display: flex;

	align-items: center;
	justify-content: center;

	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	line-height: 22px;
	font-weight: 200;
	text-align: center;
	color: rgb(41, 41, 41);
	margin-bottom: 20px;
}

.subscription-item {
	border-radius: 8px;
	height: 80px;
	width: 90%;
	max-width: 500px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	border: 1px solid rgb(168, 168, 168);
}

.subscription-item > :first-child {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.subscription-item > :first-child > div {
	width: 100%;
	flex: 1;
	height: 50%;
	display: flex;
	align-items: center;
}

.name {
	padding: 0px 20px;
	font-size: 12pt;
}

.description {
	padding: 0px 20px;
	font-size: 10pt;
}

.subscription-item > :last-child {
	width: 30%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.close-btn {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.close-btn > img {
	cursor: pointer;
}


.loader {
	border-radius: 80%;
	width: 30px;
	height:30px;
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }