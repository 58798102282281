@import url(https://fonts.googleapis.com/css?family=Roboto:500);

:root {
	--navbar-height: 50px;
	--progress-bar-height: 5px;
	--bottom-bar-height: 50px;
	--online-status-time: 2s;
	--online-status-height: 20px;
	--safe-area-inset: 20px;

	--empty-state-padding: 40px;
	--empty-state-width: 150px;
	--toc-opacity: 0.95;

	--article-settings-options-padding: 20px;
	--font-size-background: linear-gradient(145deg, #e6e6e6, #ffffff);
	--font-size-box-shadow: 5px 5px 4px #e8e8e8, -5px -5px 4px #ffffff;

	--font-size-background-pressed: #fff;
	--font-size-box-shadow-pressed: inset 12px 12px 24px #d9d9d9,
		inset -12px -12px 24px #ffffff;

	--transition-animation: height 400ms ease-in-out;
	--issue-navigator-size: 10vw;

	--swiper-theme-color: #333 !important;

	--hotspot-color-fill: #4285f4;
}

/*RESET*/
html {
	position: fixed;
	height: 100%;
	overflow: hidden;
	text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

html,
body {
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeSpeed;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	text-rendering: auto;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.swiper-pagination {
	bottom: 0px !important;
}

.gallery .swiper-slide img {
	object-fit: contain;
	/*TODO*/
	/*max-height: 500px !important;*/
	margin: 0 auto !important;
	text-align: center !important;
	display: block !important;
}

/*#root > div {
	overflow: hidden;
}*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	scroll-behavior: smooth;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
article ol,
article ul {
	padding-inline-start: 40px;
}
article ol {
	list-style: decimal;
}
article ul {
	list-style: disc;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

article .component {
	position: relative;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
	font-size: 16px;
}

/*END RESET*/

@media screen and (min-width: 767px) {
	:root {
		--issue-navigator-size: 7vw;
	}
}

@media screen and (max-width: 767px) {
	:root {
		--navbar-height: 50px;
	}
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body > div {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

main {
	height: 100%;
	width: 100%;
}

sup,
sub {
	font-size: 65%;
}

.App {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: fixed;
}

footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	min-height: var(--bottom-bar-height);
}

nav {
	/*min-height: var(--navbar-height);*/
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

button {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.StripeElement {
	height: 40px;
	padding: 10px 12px;
	width: 100%;
	color: #32325d;
	background-color: white;
	border: 1px solid transparent;
	border-radius: 4px;

	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}

.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

/*Components Default*/

.component img {
	vertical-align: middle;
}

.btn-31744 {
	display: none !important;
}

.content-protection {
	-webkit-user-select: none;
	user-select: none;
}

.content-protection article .component.text {
	-webkit-user-select: none;
	user-select: none;
}

.content-protection article img {
	pointer-events: none;
}

.gallery .swiper-button-next,
.gallery .swiper-button-prev {
	top: calc(50% - 22px);
}

/* * { overflow: visible !important; } */

/*article[dir="rtl"] section > div {
	flex-direction: column-reverse !important;
}*/

@media print {
	* {
		overflow-x: hidden;
	}

	html,
	body {
		position: static;
		overflow: visible;
		width: auto;
		height: auto;
	}

	.print-view {
		width: 100%;
		background: transparent;
	}

	.print-view > div {
		width: 100%;
		margin: auto;
		max-width: 720px;
		/*overflow-y: visible;*/
	}

	.print-view article {
		top: 0px;
	}

	.print-view article .canvas {
		height: auto !important;
	}

	/*body * {
		visibility: hidden;
	}

	.print-view article {
		left: 0px;
		top: 0px;
		position: absolute;
	}
	.print-view article p {
		page-break-before: always;
	}*/
}

.pulse-animation {
	animation-name: color;
	animation-duration: 2s;
	animation-iteration-count: 1;
}

@keyframes color {
	0% {
		fill: transparent;
	}
	50% {
		fill: var(--hotspot-color-fill);
	}
	100% {
		fill: transparent;
	}
}
