.article-container {
	display: flex;
	flex-direction: column;

	height: 100vh;
	max-width: 1024px;
	margin: auto;
}

.article-container > div {
	width: 100%;
}

.issue-articles {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.image-modal {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 800;
	background: black;
	display: flex;
	flex-direction: column;
}
.image-modal > div {
	display: flex;
	width: 100%;
}

.image-modal > :first-child {
	justify-content: flex-end;
	align-items: center;
	height: var(--navbar-height);
	padding-right: 10px;
}

.image-modal > .container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.image-modal > .container > div {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
}

.issue-navigator-component {
	width: 40px;
	height: calc(100% - var(--navbar-height));
	z-index: 500;
	position: absolute;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(255, 255, 255, 0.5);
	transition: background ease-out 200ms;
}

.issue-navigator-component > div {
	background: rgb(0, 0, 0, 0.5);
	height: 24px;
	width: 24px;
	margin: auto;
}

.issue-navigator-component.left {
	left: 0;
	margin-left: 0vw;
}

.issue-navigator-component.left > div {
	mask: url('./icons/arrow-left.svg');
	mask-size: cover;
}

.issue-navigator-component.right {
	right: 0;
}

.issue-navigator-component.right > div {
	mask: url('./icons/arrow-right.svg');
	mask-size: cover;
}

.issue-navigator-component:hover {
	background: rgb(0, 0, 0, 1);
}

.issue-navigator-component:hover > div {
	background: #fff;
}

.image-modal-content {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	object-fit: contain;
}

.image-modal-x-icon > button {
	mask: url('./icons/x-icon.svg');
	background: white;
	margin: auto;
	height: 24px;
	width: 24px;
}

.progress-bar-container {
	width: 100%;
	height: 5px;
	background: #000;
	-webkit-box-shadow: 9px 10px 6px -6px rgba(0, 0, 0, 0.77);
	box-shadow: 9px 10px 6px -6px rgba(0, 0, 0, 0.77);
	border-bottom: 1px solid #eee;
}

.progress-bar {
	width: 50%;
	height: 100%;
	background: #000;
	transition: width 350ms;
}

.issue-toc {
	/*display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;*/

	left: 0px;
	position: absolute;
	height: 100%;
	width: 80%;
	overflow: auto;
	font-size: 10pt;
	z-index: 10;

	-webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.49);
	-moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.49);
	box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.49);

	transform: translateX(-100%);

	transition: transform 300ms ease-out;

	background-color: rgb(255, 255, 255, var(--toc-opacity));
}

.issue-toc.open {
	transform: translateX(0%);
}

.issue-toc > div {
	cursor: pointer;
	display: flex;
	width: 100%;
	min-height: 60px;
	align-items: center;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, var(--toc-opacity));

	margin-bottom: 0px;
}

.issue-toc > .pressed {
	cursor: default;
	border-radius: 0px;
	background-color: rgba(255, 255, 255, var(--toc-opacity));
	box-shadow: inset 3px 3px 6px #d4d4d4, inset -3px -3px 6px #ffffff;
}

.issue-toc > div > p {
	/*margin: auto;*/
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.articles-container {
	position: relative;
	display: grid;
	height: 100%;
	width: 100%;
	z-index: 2;
	grid-template-columns: 0.2fr 0.8fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.issue-toc > :last-child {
	height: 15vh;
}

.not-available-image-mode {
	height: 100%;
	width: 100%;
	display: flex;
	background: white;
	justify-content: center;
}

.not-available-image-mode > div {
	background: white;
	height: 100%;
	width: 100%;
	max-width: 1024px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.not-available-image-mode > div > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.not-available-image-mode > div > div > h3 {
	font-size: 2rem;
	text-align: center;
}

.not-available-image-mode > div > div > h4 {
	text-align: center;
	font-size: 1rem;
}

.toc {
	width: 100%;
	height: 100%;
	overflow: auto;
	border-right: 1px solid #eee;
	list-style-position: inside;
}

.toc > li {
	display: block;
	display: list-item;
	padding: 1.4em;
	font-size: 0.9rem;
	line-height: 1.5;
}

.toc > .item {
	cursor: pointer;
	transition: all ease-out 200ms;
}

/*.toc > .item > h4 {
	font-size: 0.9rem;
	line-height: 1.5;
}

.toc > .item > small {
	padding-top: 5px;
	font-size: 0.6rem;
}*/

/* smartphones, touchscreens */
/*@media (hover: none) and (pointer: coarse) {
	.issue-navigator-component {
		display: none;
		z-index: 0;
	}
}*/

@media screen and (max-width: 767px) {
	.articles-container {
		grid-template-columns: 0fr 1fr;
	}
	.issue-navigator-component.left {
		margin-left: 0vw;
	}
}

@media screen and (min-width: 769px) {
	.issue-navigator-component.left {
		margin-left: 20vw;
	}
}

@media screen and (min-width: 767px) {
	.issue-articles {
		height: 100vh;
	}
}

@media screen and (min-width: 1200px) {
	.issue-navigator-component {
		display: flex;
	}
}

@media (pointer: coarse) {
	.issue-navigator-component {
		display: none;
		z-index: 0;
	}
}

@media screen and (min-width: 1700px) {
	.articles-container {
		/*grid-template-columns: 0.2fr 0.8fr;*/
		grid-template-columns: 332px calc(100% - 332px);
	}
}
