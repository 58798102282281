.bookmarks {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
	background: #fafafa;
	padding-top: var(--navbar-height);

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container {
	display: flex;
	flex-direction: column;
	padding: var(--card--item-gutter) 20px;
}

.bookmark {
	cursor: pointer;
	display: flex;
	min-height: var(--card-item-height);
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 15px 20px;
	margin: var(--card-item-gutter) 0px;
	border-radius: 8px;

	box-shadow: 5px 5px 10px #f0f0f0, -5px -5px 10px #ffffff;
	border: 1px solid #e0e4ea;
	margin: 10px;
	color: black;
	background: #fff;
}

.bookmark:hover {
	border-radius: 3px;
	box-shadow: 5px 5px 10px #cfcfcf, -5px -5px 10px #ffffff;
}

.bookmark > div {
	width: 100%;
	min-width: 0;
	overflow: hidden;
	text-overflow: clip;
	white-space: normal;
}

.title {
	font-size: 14px;
}

.description {
	margin-top: auto;
	font-size: 11px;
}

.article-container {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
}

.image-modal {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 800;
	background: black;
	display: flex;
	flex-direction: column;
}
.image-modal > div {
	display: flex;
	width: 100%;
}

.image-modal > :first-child {
	justify-content: flex-end;
	align-items: center;
	height: var(--navbar-height);
	padding-right: 10px;
}

.image-modal > .container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.image-modal > .container > div {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
}

.image-modal-x-icon > button {
	mask: url('./icons/x-icon.svg');
	background: white;
	margin: auto;
	height: 24px;
	width: 24px;
}

.image-modal-content {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	object-fit: contain;
}

@media screen and (min-width: 769px) {
	.bookmarks {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.container {
		width: calc(100% - var(--bottom-bar-height));
		margin-left: var(--bottom-bar-height);
	}

	.image-modal > .container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(
			100vh - var(--navbar-height)- env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.image-modal > .container > div {
		width: 100%;
		max-height: calc(
			100vh - var(--navbar-height)- env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: auto;
	}

	.article-container {
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: auto;
	}
}
