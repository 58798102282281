.sm-search-container {
	width: 0px;
	display: none;
}

.sm-search-box {
	height: 0px;
	width: 100%;
	display: none;
	align-items: center;
}

.sm-search-box > input {
	width: 100%;
	height: 100%;
	background: rgba(250, 250, 250, 1);
	border-radius: 4px;
	padding-left: 32px;
	outline: none;
	box-shadow: none;
	font-size: 0.9rem;
	color: #3d3d3d;
	border: 1px solid rgba(219, 219, 219, 1);
}

.sm-search-box > input::placeholder {
	color: #c4c4c4;
	font-size: 0.9rem;
}

.search-icon {
	mask: url('./icon-search.svg');
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: 120%;
	background: #c4c4c4;
	position: absolute;
	margin-left: 8px;
	height: 16px;
	width: 16px;
}

.clear-icon {
	mask: url('./icon-close.svg');
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: 120%;
	background: #c4c4c4;
	position: absolute;
	margin-right: 18px;
	height: 16px;
	width: 16px;
	right: 0;
}

.spinner {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	border: 1px solid #c4c4c4;
	border-radius: 50%;
	border-top-color: #3d3d3d;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	margin-right: 18px;
	right: 0;
  }

.sm-result-list {
	max-height: 400px;
	overflow-y: auto;
	width: 100%;
	margin-top: 14px;
	background: #fff;
	border: 1px solid #c3c3c3;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
}

.sm-result-not-found {
	width: 100%;
	min-height: 40px;
	max-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: italic;
	color: #c3c3c3;
	font-size: 0.9rem;
}

.sm-result-item {
	width: 100%;
	min-height: 60px;
	max-height: 60px;
	border-bottom: 1px solid #c3c3c3;
	display: grid;
	grid-template-columns: 0.45fr 1.55fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.sm-result-item:last-of-type {
	border-bottom: 0px;
}

.sm-result-item > .image {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sm-result-item > .image > img {
	height: 42px;
	width: 42px;
	border-radius: 4px;
	object-fit: cover;
}

/*.sm-result-item > .content {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}*/

.sm-result-item > .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.sm-result-item > .content > * {
	margin: 0;
}

.sm-result-item > .content h3 {
	font-size: 0.85rem;
	width: 200px;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sm-result-item > .content p {
	margin-top: 4px;
	font-size: 0.65rem;
	color: #3d3d3d;
	
	width: 200px;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sm-result-item > .content small {
	margin-top: 4px;
	display: block;
	font-size: 0.6rem;
	color: #c4c4c4;
	font-style: italic;
	text-align: left;
	margin-bottom: 4px;
}

@media screen and (min-width: 1024px) {
	.sm-search-container {
		width: 50px;
		transition: width ease-out 220ms;
	}
	.sm-search-container.close button{
		width: 32px;
		height: 32px;
		border: none;
		margin: auto;
		background: none;
		outline: none;
	}

	.sm-search-box {
		display: flex;
		height: 100%;
	}

	.sm-search-container.open {
		width: 300px;
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
