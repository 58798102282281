.replica-page {
	display: block;

	/*width: 100%;*/
	position: relative;
}

.replica-page img {
	display: block;
	/*position: absolute;*/
	object-fit: cover;
	opacity: 1;
	margin: auto;

	/*max-width: 100%;
    max-height: 100%;*/
}

.replica-page canvas {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 1;
	margin-left: auto;
	margin-right: auto;
	/*max-width: 100%;
    max-height: 100%;*/
}

.replica-page svg {
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0.5;
	/*visibility: hidden;*/
}

.replica-page svg rect {
	cursor: pointer;
	fill: transparent;
	transition: fill ease-out 320ms;
}

.replica-page svg rect:hover {
	cursor: pointer;
	fill: var(--hotspot-color-fill);
}
