.font-size-option-holder {
	display: none;
	justify-content: center;
	align-items: center;
	width: 40vw;
	max-width: 350px;
	z-index: 200;

	margin-top: var(--navbar-height);
	position: fixed;
	height: var(--navbar-height);

	box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.4);

	transition: bottom 400ms ease-out;
	z-index: 100;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.font-size-option-holder > div {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.font-size-option-holder > div > :first-child {
	margin-left: 10px;
}

.font-size-option-holder > div > :last-child {
	margin-right: 10px;
}

.fontbar-icon {
	width: 50%;
	margin: auto;
	padding: 6px;
	mask-size: cover;
	display: inline-block;

	border-radius: 0px;
}

.fontbar-icon:hover {
	cursor: pointer;
}

.fontbar-icon.icon-font-increase > button {
	mask: url('../icons/icon-font-increase.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.fontbar-icon.icon-font-decrease > button {
	mask: url('../icons/icon-font-decrease.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

@media screen and (min-width: 767px) {
	.font-size-option-holder {
		display: flex;
	}
}
