.lock {
	height: 100vh;
    width: 100vw;
	background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lock > form {
    display: flex;
    flex-direction: column;
}

.lock-icon{
    height: 64px;
    width: 64px;
    background: #3d3d3d;
	transform: translate(0%, -50%);
	mask: url('./icons/lock.svg');
	mask-size: cover;
	margin: auto;
}

.lock-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.lock-button > button {
    border: none;
    width: 100%;
    height: 46px;
    background: #3d3d3d;
    color: white;
    border-radius: 4px;
}