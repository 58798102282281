.lightbox {
	width: 100vw;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.85);
	display: flex;
	justify-content: center;
	align-items: center;
	float: left;
	top: 0;
	z-index: 999999;
}

.lightbox > div {
	position: relative;
	max-height: 80%;
	max-width: 90%;
}

.close {
	width: 32px;
	height: 32px;
	position: absolute;
	right: 0;
	border-radius: 50%;
	margin-right: -16px;
	margin-top: -16px;
	background: white;
	border: 0px;
	z-index: 100;
	-webkit-box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);
}

.content {
	overflow-x: hidden;
	overflow-y: auto;
	background: white;
}

.content article {
	max-height: 100%;
}

@media screen and (max-width: 767px) {
	.content {
		max-height: 70vh;
	}
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
	.content {
		max-height: 70vh;
	}
}

@media screen and (min-width: 1024px) {
	.content {
		max-height: 70vh;
	}

	.close {
		width: 36px;
		height: 36px;
		margin-right: -18px;
		margin-top: -18px;
	}
}
