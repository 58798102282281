.bottom-bar {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: center;
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	text-align: center;
	z-index: 150;
	padding-bottom: env(safe-area-inset-bottom, var(--safe-area-inset));

	border-radius: 0px;
	background: #ffffff;
	border-top: 1px solid #e0e4ea;
	position: absolute;

	-webkit-box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);

	transition: bottom 240ms ease-out;
}

.bottom-bar-icon-container {
	flex-basis: 100%;
	flex: 1;
	align-self: center;
}

.bottom-bar-icon {
	width: 50%;
	margin: auto;
	padding: 6px;
	mask-size: cover;
	display: inline-block;

	border-radius: 0px;
}

.bottom-bar-icon:hover {
	cursor: pointer;
}

.bottom-bar-icon.icon-menu > button {
	mask: url('../icons/icon-menu.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-menu.selected > button {
	mask: url('../icons/icon-menu-selected.svg');
}

.bottom-bar-icon.icon-menu:hover button {
	background: #000;
}

.bottom-bar-icon.icon-reader > button {
	mask: url('../icons/icon-reader-mode.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-reader.selected > button {
	mask: url('../icons/icon-reader-mode-selected.svg');
}

.bottom-bar-icon.icon-reader:hover button {
	background: #000;
}


.bottom-bar-icon.icon-image-mode > button {
	mask: url('../icons/icon-image-mode.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-image-mode.selected > button {
	mask: url('../icons/icon-image-mode-selected.svg');
}

.bottom-bar-icon.icon-image-mode:hover button {
	background: #000;
}

.bottom-bar-icon.icon-font > button {
	mask: url('../icons/icon-font.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-font.selected > button {
	mask: url('../icons/icon-font-selected.svg');
}

.bottom-bar-icon.icon-font:hover button {
	background: #000;
}

.bottom-bar-icon.icon-font-increase > button {
	mask: url('../icons/icon-font-increase.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.font-size-option-holder {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: var(--bottom-bar-height);
	bottom: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);

	width: 100%;
	background: #ffffff;
	box-shadow: 1px -1px 3px #b5b5b5, -1px 1px 3px #ffffff;

	transition: bottom 400ms ease-out;
	z-index: 100;
}

.font-size-option-holder > div {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.font-size-option-holder > div > :first-child {
	margin-left: 10px;
}

.font-size-option-holder > div > :last-child {
	margin-right: 10px;
}

.bottom-bar-icon.icon-font-increase:hover button {
	background: #000;
}

.bottom-bar-icon.icon-font-decrease > button {
	mask: url('../icons/icon-font-decrease.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-share > button {
	mask: url('../icons/icon-share.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-bookmark > button {
	mask: url('../icons/icon-bookmark.svg');
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.bottom-bar-icon.icon-bookmark.selected > button {
	mask: url('../icons/icon-bookmark-selected.svg');
}

.bottom-bar-icon.icon-bookmark:hover button {
	background: #000;
}

.bottom-bar-icon.icon-font-decrease:hover button {
	background: #000;
}

.article-settings {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	height: 500px;
	max-height: 500px;

	z-index: 100;
	padding: 0px 20px;

	border-radius: 12px;
	background: #ffffff;
	box-shadow: 1px -1px 3px #b5b5b5, -1px 1px 3px #ffffff;
	transition: var(--transition-animation);
}

.article-settings > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 60px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 0px 0px;

	border-radius: 12px;
	/*background: #ffffff;
     box-shadow: 10px 10px 20px #cfcfcf, -10px -10px 20px #ffffff;*/
}

.article-settings-option {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	transition: var(--transition-animation);
}

.article-settings-option > .title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: var(--article-settings-options-padding);
}

.article-settings-option > .actions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: var(--article-settings-options-padding);
}

.font-size-container {
	display: flex;
	flex-direction: row;
	width: 110px;
	min-width: 110px;
	height: 40px;

	justify-content: space-between;

	border-radius: 12px;
}

.font-size-decrease {
	cursor: pointer;
	height: 100%;
	width: 45%;

	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	border-radius: 12px;
	background: var(--font-size-background);
	box-shadow: var(--font-size-box-shadow);
}

.font-size-decrease:active {
	background: var(--font-size-background-pressed);
	box-shadow: var(--font-size-box-shadow-pressed);
}

.font-size-decrease.disable {
	cursor: not-allowed;
	background: #fff;
	box-shadow: none;
}

.font-size-decrease.disable > svg {
	stroke: gray;
}

.font-size-increase {
	cursor: pointer;
	height: 100%;
	width: 45%;

	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;

	border-radius: 12px;
	background: var(--font-size-background);
	box-shadow: var(--font-size-box-shadow);
	/*background-color: chartreuse;*/
}

.font-size-increase:active {
	background: var(--font-size-background-pressed);
	box-shadow: var(--font-size-box-shadow-pressed);
}

.font-size-increase.disable {
	cursor: not-allowed;
	background: #fff;
	box-shadow: none;
}

.font-size-increase.disable > svg {
	fill: gray;
}

.font-size-increase > svg {
	margin: auto;
}

.font-size-decrease > svg {
	margin: auto;
}

/*Handles toggle button*/

/* Hide default input */
.toggle input {
	display: none;
}

/* The container and background */
.toggle {
	position: relative;
	display: inline-block;
	width: 55px; /*18px*/
	height: 33px;

	border-radius: 30px;
	/*background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow:  20px 20px 60px #d9d9d9, 
             -20px -20px 60px #ffffff;*/
	background: #bbbbbb;
	box-shadow: inset 5px 5px 10px #aaaaaa, inset -5px -5px 10px #cccccc;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/*background-color: #ffffff;*/
	/*border: 1px solid #aaa;*/
	border-radius: 30px;
	transition: all 0.4s;
}

/* The sliding button */
.slider:before {
	position: absolute;
	content: '';
	width: 25px;
	height: 25px;
	left: 3px;
	top: 4px;
	background-color: #fff; /*Button color*/
	border-radius: 24px;
	transition: all 0.4s;
}

/* On checked */
input:checked + .slider {
	background: #161625;
	box-shadow: inset 5px 5px 10px #12121f, inset -5px -5px 10px #1a1a2b;
}

input:checked + .slider:before {
	transform: translateX(23px);
}

@media screen and (min-width: 767px) {
	.bottom-bar {
		display: none;
		height: 0;
	}

	.font-size-option-holder {
		display: none;
		height: 0;
	}
}
